<template>
  <div class="taskList">
    <!-- 顶部导航 -->
    <van-nav-bar class="topnav" title="任务列表" left-arrow @click-left="toBack" :border="false" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <!-- 任务列表 -->
      <div class="rwList">
        <div class="rwItem" v-for="(item,index) in list" :key="'task'+index">
          <div class="top">
            <div class="left">
              <div class="pic">
                <!-- <img src="../../public/statics/images/rank/avator.jpg" alt /> -->
                <img :src="adddom(item.thumb)" alt />
              </div>
              <div class="con">
                <div class="title">{{item.name}} {{item.times > 1 ? item.times+ '次' : ''}}</div>
                <div class="jinum">积分:{{item.score}}</div>
              </div>
            </div>
            <div class="btn" v-if="item.can_get == 1" @click="receive(item)">领取</div>
            <div class="btn" v-else-if="item.can_get == 2">已完成</div>
            <div
              class="btn"
              v-else-if="item.id == 1 || item.id == 2"
              @click="toPath('/articlePub',{})"
            >去发布</div>
            <div class="btn" v-else>待完成</div>
          </div>
          <!-- 任务 id 3 5 8没有进度条
          Id 1 2 显示去发布按钮-->
          <div class="bottom" v-if="item.id != 1 && item.id != 3 && item.id != 5 && item.id != 8">
            <van-progress
              pivot-text="橙色"
              color="#0B47AC"
              :percentage="item.log ? (item.log.times/item.times <1 ? item.log.times/item.times * 100 : 100) : 0"
              :stroke-width="7"
              :show-pivot="false"
            />
            <div
              class="num"
            >{{item.log ? (item.log.times > item.times ? item.times : item.log.times) : 0}}次</div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { Notify } from "vant";
export default {
  data() {
    return {
      list: "",
      refreshing: false, //下拉刷新
      loadShow: false,
    };
  },
  created() {
    this.loadShow = true;
    this.getList();
  },
  methods: {
    onRefresh() {
      let that = this;
      this.loadShow = true;
      that.refreshing = false;
      that.list = [];
      that.getList();
    },
    receive(item) {
      let that = this;
      console.log(item.id);
      // id为1 3 5 时跳转我的原创列表，能领的文章排在前边
      if (item.id == 1 || item.id == 3 || item.id == 5) {
        // 跳转文章详情 进行领取
        that.toPath('/my',{type:1})
      } else if (item.id == 8){
        // id为8时跳转我的转发列表，能领的文章排在前边
        that.toPath('/my',{type:2})
      } else {
        // id 不是 1 3 5 8 直接领取
        let ps = {
          token: Cookies.get("spToken"),
          task_id: item.id,
          log_id: item.log.id
        };
        that.$api.article.score(ps).then(res => {
          if (res.code == 200) {
            // console.log(res.result)
            // that.list = res.result
            // Notify({ type: "success", message: "领取成功" });
            that.$toast("领取成功")
            that.getList();
          }
        });
      }
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    getList() {
      let that = this;
      let ps = {
        token: Cookies.get("spToken")
      };
      that.$api.article.taskList(ps).then(res => {
        this.loadShow = false;
        if (res.code == 200) {
          // console.log(res.result)
          that.list = res.result;
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    }
  }
};
</script>

<style>
</style>